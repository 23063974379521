<template>
  <div
    class="row me-1 ms-1 mb-3"
    align="center"
    style="background-color:#BDBDBD; minHeight: 100px;"
    justify="space-around"
  >
    <v-col cols="12" md="5" class="d-flex align-start align-center">
      <div class="pe-3 mb-1" flat>
        Merchant
      </div>
      <v-select class="select-class" :items="items" dense solo></v-select>
    </v-col>
    <v-col cols="12" md="5" class="d-flex align-start align-center">
      <div class="pe-3 mb-1" flat>
        Item
      </div>
      <v-select class="select-class" :items="foods" dense solo></v-select>
    </v-col>
    <v-col class="d-flex justify-center pt-0">
      <v-icon color="grey-darken 4" medium >
        mdi-delete
      </v-icon>
    </v-col>
  </div>
</template>

<script>
export default {
  name: "Schedule",
  data(){
    return {
       items: ["Foo", "Bar", "Fizz", "Buzz"],
      foods: ["Bento", "Sushi", "Ramen"],
    }
  }
};
</script>

<style></style>
