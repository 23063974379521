<template>
  <v-container>
    <h3>{{ $t("message.edit-schedule") }}</h3>
    <div class="mt-10">
      <div>
        <h6 class="ms-2 mb-3">{{ date }}</h6>
      </div>
      <div v-for="(input, index) in schedulers" :key="index" class="mt-1">
        <!-- <Schedule :input="input"/> -->
        <div
          class="row me-1 ms-1 mb-2"
          align="center"
          style="background-color:#BDBDBD; minHeight: 100px;"
          justify="space-around"
        >
          <v-col cols="12" md="4" class="text-start">
            <div style="width: 100px" class="pe-3 mb-1" flat>{{ $t("message.label-merchant") }}</div>
            <v-select
              class="select-class"
              v-model="input.store_id"
              :items="shopList"
              item-text="store_name"
              item-value="id"
              @change="getShopId"
              dense
              solo
            ></v-select>
          </v-col>
          <v-col cols="12" md="4" class="text-start">
            <div class="pe-3 mb-1" flat  style="width: 100px">
              {{ $t("message.label-item") }}
            </div>
            <v-select
              class="select-class"
              v-model="input.item"
              :items="foods[index].list"
              item-text="item_name"
              item-value="id"
              dense
              @change="getItemId"
              solo
            ></v-select>
          </v-col>
          <v-col cols="12" md="3" class="text-start">
            <div class="pe-3 mb-1" flat>
              qty
            </div>
            <v-text-field
            height="30"
              v-model="input.qty"
              class="select-class qty-class"
              solo
              type="number"
               min="1"
            ></v-text-field>
          </v-col>
          <v-col class="d-flex justify-center">
            <v-icon
            class="mt-3"
              color="grey-darken 4"
              medium
              @click="removeComponent(input.id, index)"
            >
              mdi-delete
            </v-icon>
          </v-col>
        </div>
      </div>
    </div>
    <div class="mt-10" style="height: 200px">
      <v-card class="d-flex justify-end mb-6" flat>
        <v-btn class="mx-2" small fab dark color="indigo" @click="addComponent">
          <v-icon dark>
            mdi-plus
          </v-icon>
        </v-btn>
      </v-card>
    </div>
    <div class="fixed-div">
      <v-row class="mb-6" justify="space-between">
        <v-col md="6">
          <v-btn
            rounded
            small
            color="secondary"
            dark
            style="height: 35px"
            @click="$router.go(-1)"
          >
            {{ $t("message.btn-cancel") }}
          </v-btn>
        </v-col>
        <v-col md="6" class="btn-update">
          <v-btn
            rounded
            color="indigo"
            class="white--text"
            style="float: right"
            @click="submit"
            :disabled="isDisable"
          >
            {{ $t("message.btn-update") }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import Schedule from "../components/Schedule.vue";
import { mapState, mapActions } from "vuex";
import Swal from "sweetalert2";

export default {
  name: "EditSchedule",

  data() {
    return {
      foods: [],
      schedulers: [],
      count: 1,
      quantity: "",
      date: this.$router.history.current.params.date,
      isDisable: false,
      isValid: [],
      today: new Date(new Date() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
    };
  },
  components: {
    Schedule,
  },
  computed: {
    ...mapState({
      shopList: (state) => state.schedule_module.storeListSchedule,
      foodList: (state) => state.schedule_module.foods,
      menu: (state) => state.schedule_module.menu,
      qtyList: (state) => state.schedule_module.qty,
    }),
  },
  created() {
    this.storeListSchedule();
    this.fetchMenuDetail();
  },
  watch: {
    schedulers: {
      handler(newVal) {
        newVal.forEach((val) => {
          if (val.item == "" || val.store_id == "") {
            this.isDisable = true;
          } else {
            this.isDisable = false;
          }
        });
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("schedule_module", ["storeListSchedule"]),
    async getShopId(shopId) {
      await this.$store.dispatch("schedule_module/getMenu", shopId);
      this.foods[this.schedulers.length - 1].list = this.foodList;

      if (this.foods[this.schedulers.length - 1].list.length > 0) {
        this.foods[this.schedulers.length - 1].list.unshift({
          id: -1,
          store_id: shopId,
          item_name: this.$t("message.select-all"),
        });
      }
    },
    async getItemId(itemId) {
      await this.$store.dispatch("schedule_module/getQty", itemId);
      this.schedulers.map(val => {
        if (val.item == itemId){
          val.qty = this.qtyList
        }
      })

    },
    addComponent() {
      let isValueValid = this.schedulers[this.schedulers.length - 1];
      this.isValid = this.schedulers[this.schedulers.length - 1];
      if (isValueValid != null) {
        if (isValueValid.item === "" || isValueValid.store_id === "") {
          Swal.fire({
            icon: "error",
            text: this.$t("message.err-msg-complete-prev"),
          });
        } else {
          this.schedulers.push({ id: 0, store_id: "", item: "", qty: "" });
          this.foods.push({ list: [] });
        }
      } else {
        this.schedulers.push({ id: 0, store_id: "", item: "", qty: ""  });
        this.foods.push({ list: [] });
      }
    },
    async fetchMenuDetail() {
      await this.$store.dispatch("schedule_module/detailMenu", this.date);
      let menu = this.menu;
      if (!menu) {
        this.schedulers.push({ id: 0, store_id: "", item: "", qty: ""  });
        this.foods.push({ list: [] });
      } else {
        menu.forEach(async (el, i) => {
          this.schedulers.push({
            id: el.id,
            store_id: el.store_id,
            item: el.item_id,
            qty: el.qty
          });
          this.foods.push({ list: [] });
          await this.$store.dispatch("schedule_module/getMenu", el.store_id);
          // await this.$store.dispatch("schedule_module/getQty", el.item_id);
          this.foods[i].list = this.foodList;
          if (this.foods[i].list.length > 0) {
            this.foods[i].list.unshift({
              id: -1,
              store_id: el.store_id,
              item_name: this.$t("message.select-all"),
            });
          }
        });
      }
    },
    removeComponent(id, deletedIndex) {
      if (id !== 0 && this.today < this.date) {
        this.foods.splice(deletedIndex, 1);
        this.schedulers.splice(deletedIndex, 1);
        this.$store.dispatch("schedule_module/deleteMenu", id);
      } else if (id !== 0 && this.today > this.date) {
        Swal.fire({
          icon: "error",
          text: this.$t("message.cant-delete-menu"),
        });
      } else {
        this.foods.splice(deletedIndex, 1);
        this.schedulers.splice(deletedIndex, 1);
      }
    },
    submit() {
      this.schedulers.forEach((s) => {
        s.menu_date = this.date;
        s.item_id = s["item"];
        delete s["item"];
      });

      this.$store
        .dispatch("schedule_module/updateMenu", this.schedulers)
        .then((res) => {
          if (res.status == 200) {
            this.$router.push("/schedule");
          } else {
            Swal.fire({
              icon: "error",
              text: res.response.data.message,
            });
          }
        });
    },
  },
};
</script>

<style>
.select-class .v-text-field__details {
  display: none !important;
}
.qty-class .v-text-field__slot input {
  padding: 0px !important;
  max-height: 30px !important;
}
.fixed-div {
  /* display: flex; */
  /* position: absolute; */
  /* bottom: 0px;
  width: 57em; */
}
.btn-update {
  display: absolute;
  right: 0px;
}
.qty-class .v-input__control .v-input__slot {
  min-height: 37px !important;
}
</style>
